import React from 'react';

export class Home extends React.Component {
    render() {
        return (
            <div className="Polaris-Page" style={{paddingTop: 50}}>
                <p align="center" className="Polaris-DisplayText Polaris-DisplayText--sizeExtraLarge" style={{padding: '0 16px'}}>
                    Welcome to Views For Charity
                </p>
                <section style={{padding: '0 16px 32px', width: '100%', maxWidth: 800, margin: '0 auto'}}>
                    <hr style={{border: 'none', height: 1, backgroundColor: '#CCC', margin: '8px 0 16px'}}/>
                    {/*<DisplayText size="medium">Our Vision is to create exposure and support for those doing amazing work around the world.</DisplayText>*/}
                    <p className="Polaris-DisplayText Polaris-DisplayText--sizeMedium" align="center" style={{color: '#666'}}>
                        Through Views For Charity (VFC) we will connect, assist, and promote the work charities around the world. During our time with each charity we will be documenting the experience to share with you. Ad revenue generated through these videos will be given to support the highlighted charity. We're excited to share this journey with everyone and look forward to getting started!
                    </p>
                </section>
                <section style={{width: '100%', maxWidth: 650, margin: '0 auto', display: 'none'}}>
                    <div style={{display: 'flex'}}>
                        <div className="Polaris-TextField Polaris-TextField--hasValue" style={{flex: '1', marginRight: 16}}>
                            <input id="email" placeholder="Email Address" className="Polaris-TextField__Input" aria-label="Email" aria-labelledby="TextField1Label" aria-invalid="false"/>
                            <div className="Polaris-TextField__Backdrop"></div>
                        </div>
                        <button type="submit" className="Polaris-Button Polaris-Button--primary">
                            <span className="Polaris-Button__Content">
                                <span>
                                    Connect
                                </span>
                            </span>
                        </button>
                    </div>
                </section>
            </div>
        )
    }
}
