import React, { Component } from 'react';
import { AppProvider } from '@shopify/polaris';
import '@shopify/polaris/styles.css';

import { Home } from './components/Home';

class App extends Component {
    render() {
        return (
            <AppProvider>
                <Home />
            </AppProvider>
        );
    }
}

export default App;
